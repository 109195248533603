<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('inventories.create')"  to="/settings/inventories/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('inventories.new_inventory') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('inventories.name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="inventory_class">{{$t('inventories.inventory_class')}}</label>
                            <select name="" id="inventory_class" v-model="filters.inventory_class" class="custom-select">
                                <option v-for="row in inventory_class_list" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="branch_id">{{$t('inventories.branch')}}</label>
<!--                            <select name="" id="branch_id" v-model="filters.branch_id" class="custom-select">-->
<!--                                <option v-for="row in branches" :value="row.code2" :key="row.code2">-->
<!--                                    {{ row.name }}-->
<!--                                </option>-->
<!--                            </select>-->
                            <multiselect v-model="branch"
                                         id="branch_id"
                                         :placeholder="$t('inventories.branch')"
                                         label="name"
                                         track-by="id"
                                         :options="branches"
                                         :multiple="false"
                                         :taggable="true"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div class="d-flex justify-content-end mb-5">
                    <custom-export-data v-if="$can('inventories.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                </div>
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('inventories.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else :disabled="true" size="lg" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('inventories.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('inventories.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-icon small v-b-tooltip.hover class="ml-2" :title="$t('MENU.inventory_statistics_management')" v-if="$can('inventory_statistics.list')" color="blue darken-2" @click="inventory_statistics(props.row)">fas fa-list</v-icon>

                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                mainRoute: 'settings/inventories',
                subMainRoute: 'settings/inventory',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                filters: {
                    name: '',
                    inventory_class: '',
                    branch_id: '',
                    status: '',
                },
                branches: [],
                inventory_class_list: [
                    {id: 1, name: this.$t('inventory_class_list.main_inventory')}, // المخزون الرئيسي
                    {id: 2, name: this.$t('inventory_class_list.factory_inventory')},  // جرد المصنع
                    {id: 3, name: this.$t('inventory_class_list.custom_inventory')}, // جرد مخصص
                    {id: 4, name: this.$t('inventory_class_list.shipping_inventory')}, // مخزون الشحن
                    {id: 5, name: this.$t('inventory_class_list.maintenance_inventory')}, // جرد الصيانة
                    {id: 6, name: this.$t('inventory_class_list.temp_inventory')}, // جرد مؤقت
                ],
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'branch_name', 'created_at', 'inventory_class_name', 'status', 'actions'],


                disabledButton: false,
                validation: [],
                dataList : [],
                branch: null,
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('inventories.name')] = 'name';
                fields[this.$t('inventories.branch')] = 'branch_name';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('inventories.inventory_class')] = 'inventory_class_name';
                fields[this.$t('status')] = 'status';
                return fields;
            },
            fileName : function () {
                return this.$t('inventories.inventory');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('inventories.name'),
                        branch_name: that.$t('inventories.branch'),
                        created_at: that.$t('created_at'),
                        inventory_class_name: that.$t('inventories.inventory_class'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        watch:{
            branch: function (val) {
                if (val){
                    this.filters.branch_id = val.id;
                }else {
                    this.filters.branch_id = null;
                }
            }
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventories")}]);
            this.getBranches();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.inventory_class = '';
                this.filters.branch_id = '';
                this.filters.status = '';
                this.branch = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'inventories.edit', params: {id: item.id}});
            },
            inventory_statistics (item) {
                this.$router.push({name: 'inventory_statistics.index', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    status: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            getBranches() {
                ApiService.get(this.mainRouteDependency + `/branches`).then((response) => {
                    this.branches = response.data.data;
                });
            },
        },
    };
</script>
